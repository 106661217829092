import React from "react"
import "./ArticleCards.scss"
import { LazyLoadImage } from "react-lazy-load-image-component"
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  EmailShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share"
import { dottedText } from "../../utility/general"
const ArticleCards = ({ imageSrc, heading, extract, postUrl, onLiked }) => {
  return (
    <div className="article-card" style={{}}>
      <div
        className="card"
        style={{
          borderRadius: "14px",
        }}
      >
        <div
          className="card-image"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(postUrl, "_blank")
          }}
        >
          <figure className="image is-4by3">
            <LazyLoadImage
              src={imageSrc}
              alt="Placeholder image"
              style={{ borderRadius: "14px 14px 0px 0px", objectFit: "cover" }}
              effect="opacity"
            />
          </figure>
        </div>
        <div
          className="card-content"
          style={{ cursor: "pointer", minHeight: "170px" }}
          onClick={() => {
            window.open(postUrl, "_blank")
          }}
        >
          <div className="media">
            <div
              className="media-content"
              style={{
                minHeight: "36px",
                marginBottom: "5px",
              }}
            >
              <p style={{ lineHeight: "1.5" }} className="title is-6">
                {heading}
              </p>
            </div>
          </div>
          <div className="content" style={{ fontSize: "14px" }}>
            {dottedText(extract, 80)}
            ...{" "}
            <a className="link" href={postUrl}>
              read more
            </a>
          </div>
        </div>
        <footer
          className="card-footer"
          style={{ borderRadius: "0px 0px 14px 14px " }}
        >
          {/* <p className="card-footer-item">
                  <span style={{ fontSize: "14px" }}>
                    Read <a href="#">blog post</a>
                  </span>
                </p> */}
          <p className="card-footer-item">
            <span style={{ fontSize: "14px" }}>
              <TwitterShareButton
                url={postUrl}
                onClick={() => {
                  // analytics.logEvent("social_share_twitter")
                }}
                // title="I 💛 voyay!ge. Get the yay! back to your vacation. Check it out!"
                title={`${heading} @voyayge`}
                via="voyayge"
                hashtags={["govoyayge", "voyayge", "checkitout"]}
              >
                <div>
                  <TwitterIcon
                    borderRadius={4}
                    size={42}
                    iconFillColor="#fec600"
                    bgStyle={{ fill: "#ffffff" }}
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "-10px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Twitter
                  </p>
                </div>
              </TwitterShareButton>{" "}
            </span>
          </p>
          <p
            className="card-footer-item"
            style={{ borderRadius: "0px 0px 14px 0px" }}
          >
            <span style={{ fontSize: "14px" }}>
              <FacebookShareButton
                url={postUrl}
                onClick={() => {
                  // analytics.logEvent("social_share_twitter")
                }}
                // title="I 💛 voyay!ge. Get the yay! back to your vacation. Check it out!"
                title="Check out this interesting read"
                via="voyayge"
                hashtags={["govoyayge", "voyayge", "checkitout"]}
              >
                <FacebookIcon
                  borderRadius={4}
                  size={42}
                  iconFillColor="#fec600"
                  bgStyle={{ fill: "#ffffff" }}
                />
                <p
                  style={{
                    fontSize: "12px",
                    marginTop: "-10px",
                    letterSpacing: "0.5px",
                  }}
                >
                  Facebook
                </p>
              </FacebookShareButton>
            </span>
          </p>
        </footer>
      </div>
    </div>
  )
}

export default ArticleCards
