import React, { Fragment } from "react"
import SignInForm from "../components/sign-in-form/SignInForm"
import Layout from "../components/layout"
import BecomeADesigner from "../components/BecomeADesigner/BecomeADesigner"
import { navigate } from "gatsby"
import LandingPage from "../components/landing-page/landingpage"
import SEO from "../components/seo"
import TripPlannerPage from "../components/TripPlannerPage/TripPlannerPage"
const checkRightPageToLoad = params => {
  return <TripPlannerPage />
}
const PlanYourTripWithLayout = () => {
  return <Fragment>{checkRightPageToLoad()}</Fragment>
}

export default () => (
  <Layout>
    <SEO pageTitle="Home" title="voyayge.com" />
    {/* {checkRightPageToLoad()} */}
    <LandingPage />
  </Layout>
  // <Layout>
  //   <SEO
  //     pageTitle="Free Travel Planner App"
  //     description="A free travel planner app to organize your trip, build your itineraries, collaborate with trip-mates."
  //   />
  //   <PlanYourTripWithLayout />
  // </Layout>
)
