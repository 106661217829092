// import "./landingpage.scss"
import { Link, navigate } from "gatsby"
import loadable from "@loadable/component"
// import { gsap } from "gsap"
// import { ScrollTrigger } from "gsap/ScrollTrigger"
import is from "is_js"
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size"
import "pure-react-carousel/dist/react-carousel.es.css"
import React, { useContext, useEffect, useRef, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/black-and-white.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import designTools from "../../images/design-tools.png"
import paid from "../../images/paid.png"
import profileStandout from "../../images/profile-standout.png"
import wiggle from "../../images/wiggle.svg"
import Accordion from "../Accordion/Accordion"
import { analytics } from "../firebase/firebase"
import Image from "../image"
import { AuthUserContext } from "../session"
import TestimonialsCardComponent from "../TestimonialsCardComponent/TestimonialsCardComponent"
import { FirebaseContext } from "./../../components/firebase"
import * as ROUTES from "./../../constants/routes"
import logoSVG from "./../../images/logo.png"
import voyaygeHeart from "./../../images/voyayge-hrt.png"
import voyaygeHeartMobile from "./../../images/voyayge-hrt-mobile.png"
import screenshot1 from "./../../images/screenshot1.png"
import screenshot2 from "./../../images/screenshot2.png"
import screenshot3 from "./../../images/screenshot3.png"
import screenshot4 from "./../../images/screenshot4.png"
import screenshot5 from "./../../images/screenshot5.png"
import "./TripPlannerPage.scss"
import ArticleCards from "../ArticleCards/ArticleCards"
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slider,
  Slide,
  Dot,
} from "pure-react-carousel"
const FetchAsyncDesignerCards = loadable(() =>
  import("../DesignerCards/FetchAsyncDesignerCards")
)
let backgroundPosition = is.mobile() ? Math.floor(Math.random() * 100) : 55

function TripPlannerPage() {
  const [atTop, setAtTop] = useState(true)
  const [state, setState] = useState("none")
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  const authContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [newsletterEmailError, setNewsletterEmailError] = useState(false)
  const [newsletterEmail, setNewsletterEmail] = useState("")
  const [newsletterEmailLoading, setNewsletterEmailLoading] = useState(false)
  const [
    newsletterSubscriptionState,
    setNewsletterSubscriptionState,
  ] = useState("default")

  useEffect(() => {
    document.addEventListener("scroll", positionSetter)
    return () => window.removeEventListener("scroll", positionSetter)
  }, [])
  const positionSetter = e => {
    if (window.scrollY === 0) {
      //console.log("Scroll reached top")
      setAtTop(true)
    } else {
      setAtTop(false)
    }
  }
  useEffect(() => {
    let tl

    // if (typeof window !== "undefined") {
    //   gsap.registerPlugin(ScrollTrigger)
    //   tl = gsap.timeline({ delay: 0.2 })
    // }
    // tl.to(scrollRef, {
    //   scrollTrigger: {
    //     trigger: scrollRefStart,
    //     endTrigger: scrollRef,
    //     toggleActions: "restart none reverse pause",
    //     scrub: true,
    //     start: "0px",
    //     end: "100%",
    //     // markers: true,
    //   },
    //   opacity: 0,

    //   ease: "power4.out",
    //   duration: 3,
    // })

    // tl.from(designerRef1, {
    //   scrollTrigger: {
    //     trigger: trigger2,
    //     toggleActions: "restart none reverse pause",
    //     scrub: true,
    //     start: "top center",
    //     //markers: true,
    //   },
    //   opacity: 0,
    //   x: -20,

    //   ease: "power4.out",
    //   duration: 3,
    // })
    // tl.from(designerRef2, {
    //   scrollTrigger: {
    //     trigger: trigger2,
    //     toggleActions: "restart none reverse pause",
    //     scrub: true,
    //     start: "top center",
    //     //markers: true,
    //   },
    //   opacity: 0,
    //   //x: 10,

    //   ease: "power4.out",
    //   duration: 3,
    // })
    // tl.from(designerRef3, {
    //   scrollTrigger: {
    //     trigger: trigger2,
    //     toggleActions: "restart none reverse pause",
    //     scrub: true,
    //     start: "top center",
    //     //markers: true,
    //   },
    //   opacity: 0,
    //   x: 20,

    //   ease: "power4.out",
    //   duration: 3,
    // })
    // tl.utils.toArray(".section").forEach((panel, i) => {
    //   ScrollTrigger.create({
    //     trigger: panel,
    //     start: "top top",
    //     pin: true,
    //     pinSpacing: false,
    //   })
    // })
  }, [])
  const faqWrapper = qNa => {
    return (
      // <div className="columns is-tablet is-multiline">
      //   <div className="column is-6-desktop is-6-tablet">
      //     <div className="columns is-tablet is-multiline">
      //       <div className="column is-12-desktop is-12-tablet">{firstPair}</div>
      //     </div>
      //   </div>
      //   <div className="column is-6-desktop is-6-tablet">
      //     <div className="columns is-tablet is-multiline">
      //       <div className="column is-12-desktop is-12-tablet">
      //         {secondPair}
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>{qNa}</div>
    )
  }

  var scrollRef = useRef(null)
  var scrollRefStart = useRef(null)
  var logoRef = useRef(null)
  var travelerDetailsRef = useRef(null)
  var priceCardRef = useRef(null)
  var trigger1 = useRef(null)
  var trigger2 = useRef(null)
  var designerRef1 = useRef(null)
  var designerRef2 = useRef(null)
  var designerRef3 = useRef(null)
  const screenWidth = useWindowWidth()
  return (
    <>
      {/* <ProgressBar height="10" bgcolor="#fec600" duration="1" /> */}
      <div ref={e => (scrollRefStart = e)}></div>
      <div
        className="scroll-indicator"
        id="scroll-indicator"
        ref={e => (scrollRef = e)}
        style={{
          display: "block",
          width: "20px",
          height: "50px",
          position: "absolute",
          bottom: "50px",
          left: "50%",
          marginLeft: "-10px",
          color: "#fec600",
        }}
      >
        {/* <p style={{ textAlign: "center" }}>Scroll Down</p> */}
        <i className="fal fa-angle-double-down fa-3x"></i>
        {/* <img src={scroll} alt="scroll-down" /> */}
      </div>
      <section
        // className="section is-large map-background"
        className="section"
        style={{ minHeight: "100vh" }}
      >
        <Image
          src="worldMap"
          style={{ position: "unset" }}
          // className="gatsby-hero-image"
          // fluid={data1.image.childImageSharp.fluid}
          alt="voyayge hero image"
        ></Image>

        <div className="hero-body byot-hero-body center">
          <div
            className="has-text-centered hero-container"
            ref={e => (logoRef = e)}
            style={{
              opacity: "1",
              overflow: "hidden",
              // maxWidth: screenWidth > 1200 ? "1200px" : "920px",
              textAlign: "left",
              margin: "0 auto",
            }}
          >
            <div
              className="hero-logo-small"
              style={{
                textAlign: "left",
                // maxWidth: "150px",
                //marginTop: "26px",
                // margin: "0 auto",
                marginBottom: "40px",
              }}
            >
              <LazyLoadImage
                src={logoSVG}
                alt="voyayge company logo"
                style={{ opacity: "1", display: "none" }}
                // width="750px"
                // height="170px"
                effect="blur"
              />
            </div>
            {/* <p className="hero-caption">GET YOUR PERSONALIZED TRAVEL PLAN</p> */}
            <span
              className="hero-hrt-img"
              // style={{
              //   position: "absolute",
              //   top: "-4%",
              //   display: screenWidth > 1200 ? "" : "none",
              //   paddingLeft: "20px",
              // }}
            >
              <LazyLoadImage
                src={screenWidth > 1024 ? voyaygeHeart : voyaygeHeartMobile}
                alt="voyayge company logo"
                style={{ opacity: "0.8", display: "", paddingLeft: "20px" }}
                // width="750px"
                // height="170px"
                // className="heart"
                effect="blur"
              />
            </span>
            <h1
              className="byot-hero-caption"
              style={{
                // textTransform: "uppercase",
                color: "#4a4a4a",
                lineHeight: "1.1",
                // fontSize: "48px",
                // letterSpacing: "0.5px",
                // fontWeight: "bold",
                textAlign: "left",
                // maxWidth: screenWidth > 1200 ? "600px" : "920px",
              }}
            >
              Plan, Organize & Collaborate
              <span
                className="byot-yay"
                style={{
                  color: "#fec600",
                  // fontFamily: "Montserrat",
                  // fontSize: "84px",
                  display: "none",
                }}
              >
                {" "}
                {/* Travel Adventure */}
                Trip
              </span>
              <span style={{ color: "#ffc600" }}>.</span>
              <span
                // className="gradient-underline"
                style={{
                  textDecorationColor: "#fec600",
                  textDecorationLine: "underline",

                  // textDecorationStyle: "wavy",
                }}
              ></span>{" "}
            </h1>

            <p
              className="byot-hero-caption-2"
              style={{
                textTransform: "",
                color: "#4a4a4a",
                fontWeight: "400",
                // maxWidth: screenWidth > 1200 ? "660px" : "720px",
                // fontSize: screenWidth > 1200 ? "22px" : "18px",
              }}
            >
              {/* No more hassle of juggling multiple applications to plan, organize
              and collaborate on your */}
              Your one free travel planner app to organize your trip, build your
              itineraries, collaborate with trip-mates and much more.
              <span
                // className="gradient-underline"
                style={
                  {
                    // textDecorationColor: "#fec600",
                    // textDecorationLine: "underline",
                    // textDecorationThickness: "3px",
                    // textDecorationStyle: "wavy",
                  }
                }
              >
                {" "}
                {/* next vacation. */}
              </span>{" "}
              {/* on{" "}
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  // fontFamily: "Montserrat",
                }}
              >
                {" "}
                voyay!ge
              </span>{" "} */}
              {/* <strong
                style={{
                  // fontSize: "20px",
                  fontWeight: "700",
                  fontFamily: "Montserrat",
                }}
              >
                {" "}
                voyay!ge
              </strong>{" "} */}
              {/* Get your personalized travel plan crafted! */}
              {/* Democratizing bespoke travel planning. */}
              {/* Platform for bespoke travel itineraries */}
            </p>
            <div
              style={{
                // maxWidth: "250px",
                // margin: "30px auto",
                marginTop: "15px",
              }}
              className="hero-button-container"
            >
              <button
                // className="button is-medium is-warning is-inverted brand-shadow"
                className="brand-shadow button is-warning"
                style={{
                  // width: "100%",
                  fontWeight: "400",
                  letterSpacing: "0.5px",
                  backgroundColor: "#fec600",
                  // borderRadius: "12px",
                }}
                onClick={e => {
                  if (authContext) navigate("/app/dashboard")
                  else {
                    analytics.logEvent("byot_hero_signup_traveler_click")
                    navigate("/signup-as-traveler")
                  }
                }}
              >
                Start Planning
              </button>
            </div>
            {/* <div
              style={{
                // maxWidth: "250px",
                // margin: "30px auto",
                marginTop: "10px",
              }}
              // className="hero-button-container"
            >

              <p
                // className="button is-medium is-warning is-inverted brand-shadow"
                className=""
                style={{
                  width: "100%",
                  textAlign: "left",
                  textDecoration: "underline",
                  textDecorationColor: "#fec600",
                  textDecorationThickness: "3px",
                  fontSize: "18px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                onClick={e => {
                  analytics.logEvent("hero_signup_click")
                  if (authContext) navigate("/app/dashboard")
                  else navigate("/signup-as-traveler")
                }}
              >
                Get started to experience the difference{" "}
                <i
                  className="fas fa-long-arrow-right"
                  style={{
                    color: "#fec600",
                    marginLeft: "5px",
                    marginTop: "2px",
                  }}
                ></i>
              </p>
            </div> */}
            {/* <button className="buttonis-warning">Get Started</button> */}
          </div>
        </div>
        <span
          className="scroll-btn"
          ref={e => (scrollRef = e)}
          style={{ position: "absolute", bottom: "10px" }}
        >
          <a href="#become-designer">
            <i
              style={{ color: "#fec600" }}
              className="fal fa-angle-double-down fa-3x"
            ></i>
            {/* <span className="mouse">
              <span></span>
            </span>{" "} */}
            {/* <p style={{ textAlign: "center" }}>Scroll Down</p> */}
          </a>
        </span>
      </section>

      <section
        // className="section is-medium gradient"
        className="section is-small"
        style={{
          // background: "#fec600",
          borderRadius: "0px",
          height: "auto",
          // marginTop: "-60px",
          padding: "0px",
          // border: ".2px solid #fec600",
          // backgroundColor: "#BCBCBC",
        }}
        // id="become-designer"
        ref={e => (trigger2 = e)}
      >
        <div className="container">
          <h2 className="section-header" id="become-designer">
            <span
              style={{
                color: "#4a4a4a",
                display: "none",
              }}
            >
              How It Works
            </span>
            <span
              style={{
                color: "#fec600",
                display: "none",
              }}
            >
              .
            </span>
            {/* Become a <br></br> travel designer */}
          </h2>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "1.8",
              marginTop: "15px",
              display: "none",
            }}
          >
            Is traveling your passion? Have you acquired unique local knowledge
            over the years and loves performing extensive research to plan
            trips? Then use your travel wisdom and love for planning to help
            craft personalized itineraries for other travelers and make money!
            <br></br> <br></br>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "600",
                lineHeight: "1.7",
                marginTop: "15px",
                // textTransform: "uppercase",
              }}
            >
              Here's how it works:
            </span>
            <br />
            <br />
          </p>
          <div className="columns">
            <div className="column is-6">
              <div
                className="columns"
                style={{
                  // padding: "0px 20px",
                  // alignItems: "flex-start",
                  flexDirection: "column",
                  maxWidth: "400px",
                  margin: "0px auto",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100%",
                }}
              >
                <div
                  className="column"
                  ref={e => (designerRef2 = e)}
                  style={{ textAlign: "center" }}
                >
                  <p></p>
                </div>
                <div
                  className="column"
                  ref={e => (designerRef2 = e)}
                  style={{ textAlign: "center" }}
                >
                  <h4
                    style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      // marginBottom: "5px",
                    }}
                    className={"gradient-card"}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "800",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        color: "#fec600",
                      }}
                    >
                      {/* <i
                    class="far fa-angle-right"
                    style={{ color: "#fec600", marginLeft: "-10px" }}
                  ></i>{" "} */}
                      Build Your Travel Plan
                    </span>{" "}
                  </h4>
                  <p
                    style={{
                      marginTop: "5px",
                      fontSize: "16px",
                      fontWeight: "400",
                      marginBottom: "10px",
                      lineHeight: "1.5",
                    }}
                  >
                    {" "}
                    Build & organize your daily plan and view them directly on
                    the map.
                  </p>
                </div>
                <div
                  className="column"
                  ref={e => (designerRef1 = e)}
                  style={{ textAlign: "center" }}
                >
                  {/* <p style={{ fontSize: "65px", fontWeight: "300" }}>Traveler</p> */}
                  {/* <p style={{ fontSize: "65px", fontWeight: "300" }}>1</p> */}
                  {/* <div style={{ maxWidth: "130px", margin: "0 auto" }}>
                  <img src={one} alt="" />
                </div> */}
                  {/* <div
                style={{
                  width: "100px",
                  height: "111px",
                  margin: "0 auto",
                  marginTop: "50px",
                  marginBottom: "20px",
                }}
              >
                <LazyLoadImage
                  src={profileStandout}
                  width="100px"
                  height="111px"
                  alt="build travel designer profile"
                  effect="blur"
                />
              </div> */}
                  <h4
                    style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                    className={"gradient-card"}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "800",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        color: "#fec600",
                      }}
                    >
                      Collaborate with trip-mates
                    </span>{" "}
                  </h4>
                  <p
                    style={{
                      marginTop: "5px",
                      fontSize: "16px",
                      fontWeight: "400",
                      marginBottom: "10px",
                      lineHeight: "1.5",
                    }}
                  >
                    Trip mates can collaborate without the hassle of sharing
                    documents.
                  </p>
                </div>

                <div
                  className="column"
                  ref={e => (designerRef3 = e)}
                  style={{ textAlign: "center" }}
                >
                  {/* <p style={{ fontSize: "65px", fontWeight: "300" }}>3</p> */}
                  {/* <div style={{ maxWidth: "130px", margin: "0 auto" }}>
                  <img src={three} alt="" />
                </div> */}
                  {/* <div
                style={{
                  width: "100px",
                  height: "111px",
                  margin: "0 auto",
                  marginTop: "50px",
                  marginBottom: "20px",
                }}
              >
                <LazyLoadImage
                  style={{
                    marginTop: "20px",
                  }}
                  width="100px"
                  height="111px"
                  src={paid}
                  alt="get paid for creating travel plans"
                  effect="blur"
                />
              </div> */}
                  <h4
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                    className={"gradient-card"}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "800",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        color: "#fec600",
                      }}
                    >
                      Attractions Recommendation
                    </span>{" "}
                  </h4>
                  <p
                    style={{
                      marginTop: "5px",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "1.5",
                      marginBottom: "10px",
                    }}
                  >
                    Add top destination attraction to your itinerary to ensure
                    you don't miss them.
                  </p>
                </div>
                <div
                  className="column"
                  ref={e => (designerRef1 = e)}
                  style={{ textAlign: "center" }}
                >
                  {/* <div
                style={{
                  width: "100px",
                  height: "111px",
                  margin: "0 auto",
                  marginTop: "50px",
                  marginBottom: "20px",
                }}
              >
                <LazyLoadImage
                  style={{
                    marginTop: "20px",
                  }}
                  width="100px"
                  height="111px"
                  src={designTools}
                  alt="design a bespoke travel plan"
                  effect="blur"
                />
              </div> */}
                  <h4
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                    className={"gradient-card"}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "800",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        color: "#fec600",
                      }}
                    >
                      Itinerary route optimizer
                    </span>{" "}
                  </h4>
                  <p
                    style={{
                      marginTop: "5px",
                      fontSize: "16px",
                      fontWeight: "400",
                      marginBottom: "10px",
                      lineHeight: "1.5",
                    }}
                  >
                    We'll find the best route to view all your attractions to
                    make the most of your time.
                  </p>
                </div>

                <div
                  className="column"
                  ref={e => (designerRef3 = e)}
                  style={{ textAlign: "center", display: "" }}
                >
                  {/* <div
                style={{
                  width: "100px",
                  height: "111px",
                  margin: "0 auto",
                  marginTop: "50px",
                  marginBottom: "20px",
                }}
              >
                <LazyLoadImage
                  style={{
                    marginTop: "20px",
                  }}
                  width="100px"
                  height="111px"
                  src={designTools}
                  alt="design a bespoke travel plan"
                  effect="blur"
                />
              </div> */}
                  <h4
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                    className={"gradient-card"}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "800",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        color: "#fec600",
                      }}
                    >
                      Get organized
                    </span>{" "}
                  </h4>
                  <p
                    style={{
                      marginTop: "5px",
                      fontSize: "16px",
                      fontWeight: "400",
                      marginBottom: "10px",
                      lineHeight: "1.5",
                    }}
                  >
                    Your Flight, Hotel, Travel Documents etc. in one place for
                    easy access.
                  </p>
                </div>
              </div>
            </div>
            <div className="column is-6">
              <CarouselProvider
                naturalSlideWidth={350}
                naturalSlideHeight={148}
                totalSlides={5}
                isPlaying={false}
                interval={5000}
                infinite={true}
                step={1}
                visibleSlides={1}
                //visibleSlides={5}
                // currentSlide={1}
                isIntrinsicHeight={true}
                isIntrinsicWidth={true}
                // naturalSlideWidth={275}
                className="screenshot-carousel"
              >
                <Slider style={{}}>
                  <Slide index={0} key={0}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LazyLoadImage
                        style={{
                          maxWidth: "350px",
                          maxHeight: "600px",
                          padding: "20px",
                        }}
                        className=""
                        src={screenshot1}
                        alt=""
                        effect="blur"
                      />
                    </div>
                  </Slide>
                  <Slide index={1} key={1}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LazyLoadImage
                        style={{
                          maxWidth: "350px",
                          maxHeight: "600px",
                          padding: "20px",
                        }}
                        className=""
                        src={screenshot2}
                        alt=""
                        effect="blur"
                      />
                    </div>
                  </Slide>
                  <Slide
                    index={2}
                    key={2}
                    onFocus={() => {
                      console.log("Focussed")
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onFocus={() => {
                        console.log("Focussed")
                      }}
                    >
                      <LazyLoadImage
                        style={{
                          maxWidth: "350px",
                          maxHeight: "600px",
                          padding: "20px",
                        }}
                        onFocus={() => {
                          console.log("Focussed")
                        }}
                        className=""
                        src={screenshot3}
                        alt=""
                      />
                    </div>
                  </Slide>
                  <Slide index={3} key={3}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LazyLoadImage
                        style={{
                          maxWidth: "350px",
                          maxHeight: "600px",
                          padding: "20px",
                        }}
                        className=""
                        src={screenshot4}
                        alt=""
                        effect="blur"
                      />
                    </div>
                  </Slide>
                  <Slide index={4} key={4}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LazyLoadImage
                        style={{
                          maxWidth: "350px",
                          maxHeight: "600px",
                          padding: "20px",
                        }}
                        className=""
                        src={screenshot5}
                        alt=""
                        effect="blur"
                      />
                    </div>
                  </Slide>
                </Slider>
                <div style={{ maxWidth: "115px", margin: "0px auto" }}>
                  <Dot className="dots" slide={0}>
                    .
                  </Dot>
                  <Dot className="dots" slide={1}>
                    .
                  </Dot>
                  <Dot className="dots" slide={2}>
                    .
                  </Dot>
                  <Dot className="dots" slide={3}>
                    .
                  </Dot>
                  <Dot className="dots" slide={4}>
                    .
                  </Dot>
                  {/* <Dot className="dots" slide={2}>
                  .
                </Dot> */}
                </div>
                <ButtonBack
                  className="button wl-recommendations-back-button"
                  style={{
                    left: "auto",
                    top: screenWidth > 1024 ? "35%" : "65%",
                  }}
                >
                  <i className="far fa-angle-left fa-lg"></i>
                </ButtonBack>
                <ButtonNext
                  className="button wl-recommendations-next-button"
                  style={{
                    right: screenWidth > 1024 ? "40px" : "0px",
                    top: screenWidth > 1024 ? "35%" : "65%",
                  }}
                >
                  <i className="far fa-angle-right fa-lg"></i>
                </ButtonNext>
              </CarouselProvider>
              {/* <img
                  style={{ maxWidth: "350px", maxHeight: "600px", padding:'20px' }}
                  className=""
                  src={screenshot1}
                  alt=""
                /> */}
            </div>
          </div>

          <div className="columns"></div>
          <div
            style={{
              maxWidth: "300px",
              margin: "30px auto",
              marginTop: "40px",
            }}
          >
            {/* <div style={{ padding: "20px" }}>
              <img
                src={card}
                alt="card"
                style={{ filter: "brightness(1.1)" }}
              />
            </div>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "1.7",
              }}
              className={"gradient-card"}
            >
              For a limited time, the first 20 users that join the platform as a
              travel designer will receive a $50 gift card upon successful
              onboarding.
            </p> */}
            <button
              className="brand-shadow button is-warning "
              style={{
                width: "100%",
                marginTop: "0px",
                backgroundColor: "#fec600",
              }}
              onClick={e => {
                if (authContext) navigate("/app/dashboard")
                else {
                  analytics.logEvent("byot_signup_traveler_click")
                  navigate("/signup-as-traveler")
                }
              }}
            >
              Start Planning
            </button>
          </div>
        </div>
        <div
          style={{
            maxWidth: "75px",
            margin: "40px auto",
            // marginBottom: "40px",
          }}
        >
          <img src={wiggle} alt="" />
        </div>
        <div
          style={{
            maxWidth: "270px",
            margin: "0px auto",
            marginBottom: "20px",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              letterSpacing: "1px",
              color: "rgba(14,19,24,.45)",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            WHAT TRAVELERS SAY ABOUT US
          </p>
        </div>
        <div className="columns">
          <div className="column is-3-desktop"></div>
          <div className="column is-6-desktop">
            <TestimonialsCardComponent
              isTraveler={true}
              imgUrl={
                "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2Fhq5rFC4GjJS3LCE8YWgNDhhxeiB3%2Fprofile%2FprofileImage.png?alt=media&token=6538f5f2-8467-415c-9d33-76951bad89e3"
              }
              testimonial={`My sister and I have been using voyayge to plan our upcoming trip to Varadero, Cuba. voyayge has made trip planning so easy and convenient. We live in different cities and work opposite schedules. I love that we can add and input different excursions, accommodations, and flight information. voyayge easily helps us map out our destinations and gives us an accurate idea of how each day of our trip will look. If you are looking for an easy-to-use trip planner, I would definitely recommend voyayge.`}
              designerName={"Paige"}
              profileUrl={"/app/userprofiles/Ag3FtceuPXVZxsZnFSA2sUIoXjn2"}
              designationText={" "}
            />
          </div>
          <div className="column is-3-desktop"></div>
        </div>
        <div
          className="container"
          style={{
            display: "none",
            // maxWidth: "800px",
            margin: "90px auto",
            marginTop: "90px",
            // background: "#fec600",
          }}
        >
          {/* <div style={{ maxWidth: "250px", margin: "20px auto" }}>
            <Image src="eatsleeprepeat" alt="travel designer image"></Image>
          </div> */}
          <div
            style={{
              maxWidth: "270px",
              margin: "0px auto",
              marginBottom: "20px",
              display: "none",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                letterSpacing: "1px",
                color: "rgba(14,19,24,.45)",
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              WHAT DESIGNERS SAY ABOUT US
            </p>
          </div>
          <div
            className="columns"
            style={{
              display: "none",
            }}
          >
            {/* <div className="column is-1-desktop"></div> */}
            <div className="column is-6-desktop">
              <TestimonialsCardComponent
                imgUrl={
                  "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2Fmhn2NU9a4Ub9i05oF85FF9B4JIE3%2Fprofile%2FprofileImage.png?alt=media&token=e594e447-0227-4fe4-bead-41d5d6c674de"
                }
                testimonial={
                  "When creating trips for my clients, one of my favorite features was creating and saving a “bank” of my favorite sites and experiences that I can curate for my clients. I can also directly link reservations, websites, menus, etc., for easy access when abroad."
                }
                designerName={"Lauren Bebe"}
                profileUrl={"/app/userprofiles/mhn2NU9a4Ub9i05oF85FF9B4JIE3"}
              />
            </div>

            <div className="column is-6-desktop">
              <TestimonialsCardComponent
                imgUrl={
                  "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2FZEHZQtK4QhSYGcFB1udgnwbXgaE2%2Fprofile%2FprofileImage.png?alt=media&token=6c64ce0d-9026-4b56-a8a8-d79d3c3db71e"
                }
                testimonial={`voyayge has been a fantastic platform for me to work with travelers to create custom itineraries. I can track every traveler's preferences in the group and curate unique experiences for each of them. It's beneficial when kids are a part of the group. The platform makes it simple without too much back and forth and helps in creating customized itineraries faster. The final itinerary allows me to give tons of information about each destination, tour, and experience with tips and website links.`}
                designerName={"Shilpa Kulkarni"}
                profileUrl={"/app/userprofiles/ZEHZQtK4QhSYGcFB1udgnwbXgaE2"}
              />
            </div>
            {/* <div className="column is-1-desktop"></div> */}
          </div>

          {false && (
            <CarouselProvider
              // naturalSlideWidth={100}
              naturalSlideHeight={125}
              totalSlides={2}
              isPlaying={true}
              infinite={true}
              visibleSlides={1}
              isIntrinsicHeight={true}
              // currentSlide={0}
            >
              <Slider>
                <Slide index={0}>
                  <TestimonialsCardComponent
                    imgUrl={
                      "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2Fmhn2NU9a4Ub9i05oF85FF9B4JIE3%2Fprofile%2FprofileImage.png?alt=media&token=e594e447-0227-4fe4-bead-41d5d6c674de"
                    }
                    testimonial={
                      "When creating trips for my clients, one of my favorite features was creating and saving a “bank” of my favorite sites and experiences that I can use send to my clients. I can also directly link reservations, websites, menus, etc., for easy access when abroad."
                    }
                    designerName={"Lauren Bebe"}
                    profileUrl={
                      "https://localhost:8000/app/userprofiles/mhn2NU9a4Ub9i05oF85FF9B4JIE3"
                    }
                  />
                </Slide>

                <Slide index={1}>
                  <TestimonialsCardComponent
                    imgUrl={
                      "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2FZEHZQtK4QhSYGcFB1udgnwbXgaE2%2Fprofile%2FprofileImage.png?alt=media&token=6c64ce0d-9026-4b56-a8a8-d79d3c3db71e"
                    }
                    testimonial={
                      "voyayge has been a fantastic platform for me to work with travelers to create custom itineraries. I can track every traveler's preferences in the group and curate unique experiences for each of them. It's beneficial when kids are a part of the group. The platform makes it simple without too much back and forth and helps in creating customized itineraries faster. The final itinerary allows me to give tons of information about each destination, tour, and experience with tips and website links."
                    }
                    designerName={"Shilpa Kulkarni"}
                    profileUrl={
                      "https://localhost:8000/app/userprofiles/ZEHZQtK4QhSYGcFB1udgnwbXgaE2"
                    }
                  />
                </Slide>
                {/* <Slide index={2}>
                  {" "}
                  <TestimonialsCardComponent
                    imgUrl={
                      "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2FCaQ3tlqOl4VeYLlfbBQHlqmZWHI3%2Fprofile%2FprofileImage.png?alt=media&token=1402b5d4-f23e-4ac3-81d2-ee6fc0f790d4"
                    }
                    testimonial={
                      "When creating trips for my clients, one of my favorite features was creating and saving a “bank” of my favorite sites and experiences that I can use send to my clients. I can also directly link reservations, websites, menus, etc., for easy access when abroad."
                    }
                    designerName={"Carine Carringnon"}
                    profileUrl={
                      "https://localhost:8000/app/userprofiles/CaQ3tlqOl4VeYLlfbBQHlqmZWHI3"
                    }
                  />
                </Slide> */}
                {/* <Slide index={3}>
                  <FeaturedCardComponent
                    id="3"
                    click={featuredCardClickHandler}
                    title="Paris"
                    imgUrl={
                      "https://images.unsplash.com/photo-1502602898657-3e91760cbb34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80"
                    }
                  />
                </Slide>
                <Slide index={4}>
                  <FeaturedCardComponent
                    id="4"
                    click={featuredCardClickHandler}
                    title="India"
                    imgUrl={
                      "https://images.unsplash.com/photo-1564507592333-c60657eea523?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1951&q=80"
                    }
                  />
                </Slide> */}
              </Slider>
              {/* <Button.Group size={3}>
                {[...Array(slides).keys()].map(slide => (
                  <button as={Dot} key={slide} icon="circle" slide={slide} />
                ))}
              </Button.Group> */}
              <div style={{ maxWidth: "70px", margin: "0px auto" }}>
                <Dot className="dots" slide={0}>
                  .
                </Dot>
                <Dot className="dots" slide={1}>
                  .
                </Dot>
                {/* <Dot className="dots" slide={2}>
                  .
                </Dot> */}
              </div>
            </CarouselProvider>
          )}
        </div>
        <section
          className="section is-medium article-card-section"
          style={{}}
          // id="newsletter-subscribe"
        >
          <div className="container">
            {/* {displayCarousel()} */}
            <h2 className="section-header">
              <span
                style={{
                  color: "#4a4a4a",
                }}
              >
                TRAVEL<br></br>INSPIRATION
              </span>
              <span
                style={{
                  color: "#fec600",
                }}
              >
                .
              </span>
            </h2>
            {/* <CarouselProvider
              naturalSlideWidth={405}
              naturalSlideHeight={579}
              totalSlides={5}
              isPlaying={false}
              interval={5000}
              infinite={true}
              step={3}
              visibleSlides={3}
              //visibleSlides={5}
              // currentSlide={1}
              isIntrinsicHeight={true}
              isIntrinsicWidth={true}
              // naturalSlideWidth={275}
              // className="screenshot-carousel"
            >
              <Slider style={{}}>
                <Slide index={0} key={0}>
                  <ArticleCards
                    imageSrc="https://blog.voyayge.com/content/images/2021/09/1364px-Van_Gogh_-_Starry_Night_-_Google_Art_Project-1.jpg"
                    heading="12 destinations from famous paintings you can visit in real life"
                    extract="England's churches. Temples in the Middle East. Beaches in Germany. All of them have served as sources of inspiration for some of the world's greatest artists, who have transformed natural landscapes and landmarks into works of art."
                    postUrl="https://blog.voyayge.com/12-destinations-from-famous-paintings-you-can-visit-in-real-life/"
                  />
                </Slide>
                <Slide index={1} key={1}>
                  <ArticleCards
                    imageSrc="https://blog.voyayge.com/content/images/2021/09/photo-1603378995290-8d4ce0495ddd.jpeg"
                    heading="The Complete Guide to France - Where to Go, What to Eat and Tips"
                    extract="An introduction to France's must-visit destinations and the best time to visit them. Plus, a list of things to eat while in France and top tips to remember."
                    postUrl="https://blog.voyayge.com/the-complete-guide-to/"
                  />
                </Slide>
                <Slide
                  index={2}
                  key={2}
                  onFocus={() => {
                    console.log("Focussed")
                  }}
                >
                  <ArticleCards
                    imageSrc="https://blog.voyayge.com/content/images/2020/12/snowscat-_7RIy-UNfnI-unsplash.jpg"
                    heading="Visiting Patagonia"
                    extract="Patagonia runs through both Argentina and Chile. The best time
                    to visit the region ranges from October to March, during the"
                    postUrl="https://blog.voyayge.com/patagonia/"
                  />
                </Slide>
                <Slide index={3} key={3}>
                  <ArticleCards
                    imageSrc="https://images.unsplash.com/photo-1511860810434-a92f84c6f01e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDI0fHxtb3JvY2NvfGVufDB8fHx8MTYyMjMxOTI1OA&ixlib=rb-1.2.1&q=80&w=2000"
                    heading="Ten Must-See Wonders of Morocco"
                    extract="What happens when the best of Arab and African influences meet
              over endless deserts and pristine seas and colorful"
                    postUrl="https://blog.voyayge.com/wonders-of-morocco/"
                  />
                </Slide>
                <Slide index={4} key={4}>
                  <ArticleCards
                    imageSrc="https://blog.voyayge.com/content/images/2021/02/pablo-fierro-zOXUvQ3Xo3s-unsplash.jpg"
                    heading="10 Unforgettable Road Trips Across National Parks in the USA"
                    extract="From East to West, America’s National Parks bring people closer to awe-inspiring natural beauty. Explore everything from incredible landscapes to abundant wildlife on these road trips across National Parks in the USA that you will remember for ages."
                    postUrl="https://blog.voyayge.com/10-unforgettable-road-trips-across-national-parks-in-the-usa/"
                  />
                </Slide>
              </Slider>
              <div style={{ maxWidth: "115px", margin: "0px auto" }}>
                <Dot className="dots" slide={0}>
                  .
                </Dot>
                <Dot className="dots" slide={1}>
                  .
                </Dot>
                <Dot className="dots" slide={2}>
                  .
                </Dot>
                <Dot className="dots" slide={3}>
                  .
                </Dot>
                <Dot className="dots" slide={4}>
                  .
                </Dot>

              </div>
              <ButtonBack className="button wl-recommendations-back-button">
                <i className="far fa-angle-left fa-lg"></i>
              </ButtonBack>
              <ButtonNext className="button wl-recommendations-next-button">
                <i className="far fa-angle-right fa-lg"></i>
              </ButtonNext>
            </CarouselProvider> */}
            <div
              style={{
                minHeight: "325px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <ArticleCards
                imageSrc="https://blog.voyayge.com/content/images/2021/09/1364px-Van_Gogh_-_Starry_Night_-_Google_Art_Project-1.jpg"
                heading="12 destinations from famous paintings you can visit in real life"
                extract="England's churches. Temples in the Middle East. Beaches in Germany. All of them have served as sources of inspiration for some of the world's greatest artists, who have transformed natural landscapes and landmarks into works of art."
                postUrl="https://blog.voyayge.com/12-destinations-from-famous-paintings-you-can-visit-in-real-life/"
              />

              <ArticleCards
                imageSrc="https://blog.voyayge.com/content/images/2021/09/photo-1603378995290-8d4ce0495ddd.jpeg"
                heading="The Complete Guide to France - Where to Go, What to Eat and Tips"
                extract="An introduction to France's must-visit destinations and the best time to visit them. Plus, a list of things to eat while in France and top tips to remember."
                postUrl="https://blog.voyayge.com/the-complete-guide-to/"
              />
              <ArticleCards
                imageSrc="https://blog.voyayge.com/content/images/2020/12/snowscat-_7RIy-UNfnI-unsplash.jpg"
                heading="Visiting Patagonia"
                extract="Patagonia runs through both Argentina and Chile. The best time
                    to visit the region ranges from October to March, during the"
                postUrl="https://blog.voyayge.com/patagonia/"
              />

              <ArticleCards
                imageSrc="https://images.unsplash.com/photo-1511860810434-a92f84c6f01e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDI0fHxtb3JvY2NvfGVufDB8fHx8MTYyMjMxOTI1OA&ixlib=rb-1.2.1&q=80&w=2000"
                heading="Ten Must-See Wonders of Morocco"
                extract="What happens when the best of Arab and African influences meet
              over endless deserts and pristine seas and colorful"
                postUrl="https://blog.voyayge.com/wonders-of-morocco/"
              />

              <ArticleCards
                imageSrc="https://blog.voyayge.com/content/images/2021/02/pablo-fierro-zOXUvQ3Xo3s-unsplash.jpg"
                heading="10 Unforgettable Road Trips Across National Parks in the USA"
                extract="From East to West, America’s National Parks bring people closer to awe-inspiring natural beauty. Explore everything from incredible landscapes to abundant wildlife on these road trips across National Parks in the USA that you will remember for ages."
                postUrl="https://blog.voyayge.com/10-unforgettable-road-trips-across-national-parks-in-the-usa/"
              />

              <ArticleCards
                imageSrc="https://images.unsplash.com/photo-1579803978998-084e5be7ad9a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxMTc3M3wwfDF8c2VhcmNofDExfHxHcm8lQzMlOUZnbG9ja25lcnxlbnwwfHx8&ixlib=rb-1.2.1&q=80&w=2000"
                heading="Europe's Top Scenic Drives"
                extract="These drives allow you to experience all Europe's splendor, spectacular scenery, and breathtaking tourist attractions, making them a memorable, once in a lifetime experience."
                postUrl="https://blog.voyayge.com/top-10-scenic-drives-in-europe-to-take-in-2021/"
              />

              <ArticleCards
                imageSrc="https://images.unsplash.com/photo-1590856028965-5683c4b48ad0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDR8fG5pY2FyYWd1YXxlbnwwfHx8fDE2MjI1OTYwMjY&ixlib=rb-1.2.1&q=80&w=2000"
                heading="Nicaragua - The Hidden Gem of Central America"
                extract="When you think of great travel destinations in Central America, Nicaragua may not be the first place that pops into your mind but perhaps it should. This small country has as much to offer as neighbor Costa Rica but at a fraction of the price and with considerably fewer tourists. "
                postUrl="https://blog.voyayge.com/nicaragua-the-hidden-gem-of-central-america/"
              />

              <ArticleCards
                imageSrc="https://images.unsplash.com/photo-1566706546199-a93ba33ce9f7?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000&fit=max&ixid=eyJhcHBfaWQiOjExNzczfQ"
                heading="Angkor Wat – Mysteries & Myths"
                extract="Angkor Archaeological Park ranks among the most visually striking, breathtaking UNESCO World Heritage sites on the planet."
                postUrl="https://blog.voyayge.com/visiting-angkor-wat-and-angkor-thom-mysteries-and-mythologies-behind-the-ruins/"
              />
              <ArticleCards
                imageSrc="https://blog.voyayge.com/content/images/2021/09/21.jpg"
                heading="20 Fall Destinations in the USA for a fun family weekend"
                extract="Fall is that time of the year where the leaves change their colors and turn golden to renew themselves. The beautiful tourist attractions in the USA become even more enjoyable during fall."
                postUrl="https://blog.voyayge.com/20-fall-destinations-in-the-usa-for-a-fun-family-weekend/"
              />
            </div>
            <div style={{ maxWidth: "260px", margin: "50px auto" }}>
              <a
                className="button is-warning"
                href="https://blog.voyayge.com"
                // onClick={() => {
                //   window.open("https://blog.voyayge.com", "_blank")
                // }}
              >
                View more articles like these
              </a>
            </div>
          </div>
        </section>
        <div className="section is-small"></div>
        <div
          className="container"
          style={{
            maxWidth: "800px",
            margin: "30px auto",
            marginTop: "80px",
          }}
        ></div>
        <div
          className="container"
          style={{
            maxWidth: "800px",
            margin: "30px auto",
            marginTop: "80px",
          }}
        ></div>
      </section>
    </>
  )
}

export default TripPlannerPage
